export interface EnvironmentData {
  run: keyof typeof environmentData;
  development: EnvironmentConfig;
  staging: EnvironmentConfig;
  production: EnvironmentConfig;
}

export interface EnvironmentConfig {
  clientId: string;
  authority: string;
  tenantId: string;
  "api-scope": string;
  appInsightConnectionString: string;
  gpt3key:string;
  gpt4key:string;
  chatApiUrl?: string;
}
export const environmentData = {
  run: "production",

  development: {
    clientId: "553cc05f-e4ee-4d04-b7f5-e1d018b30082",
    authority:
      "https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8",
    tenantId: "30f52344-4663-4c2e-bab3-61bf24ebbed8",
    "api-scope":
      "openid 553cc05f-e4ee-4d04-b7f5-e1d018b30082/User.Impersonation",
    appInsightConnectionString:
      "InstrumentationKey=514eaa1c-389c-4bd0-9ae5-68a89872bdea;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/",
    gpt3key: "gpt-35-turbo-16k",
    gpt4key: "gpt-4-turbo-128k",
    chatApiUrl: "https://app-sitetechops-aicomp-test-001.azurewebsites.net"
  },
  staging: {
    clientId: "553cc05f-e4ee-4d04-b7f5-e1d018b30082",
    authority:
      "https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8",
    tenantId: "30f52344-4663-4c2e-bab3-61bf24ebbed8",
    "api-scope":
      "openid 553cc05f-e4ee-4d04-b7f5-e1d018b30082/User.Impersonation",
    appInsightConnectionString:
      "InstrumentationKey=514eaa1c-389c-4bd0-9ae5-68a89872bdea;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/",
    gpt3key: "gpt-35-turbo-16k",
    gpt4key: "gpt-4-turbo-128k",
    chatApiUrl: "https://app-sitetechops-aicomp-test-001.azurewebsites.net"
  },
  production: {
    clientId: "553cc05f-e4ee-4d04-b7f5-e1d018b30082",
    authority:
      "https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8",
    tenantId: "30f52344-4663-4c2e-bab3-61bf24ebbed8",
    "api-scope":
      "openid 553cc05f-e4ee-4d04-b7f5-e1d018b30082/User.Impersonation",
    appInsightConnectionString:
      "InstrumentationKey=514eaa1c-389c-4bd0-9ae5-68a89872bdea;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/",
    gpt3key: "gpt-35-turbo-16k",
    gpt4key: "gpt-4-turbo-128k",
    chatApiUrl: "https://app-sitetechops-aicomp-test-001.azurewebsites.net"
  },
} as EnvironmentData;
