import styles from "./Home.module.css";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CRow,
} from "@coreui/react";
import { useNavigate } from "react-router-dom";
import { GetRoles } from "../../services/AuthUser";
import { useAppContext } from "../../components/ContextAndProvider/Context";
const Store = () => {
  const handleLogin = (loginType: "popup" | "redirect"): void => {};
  const navigate = useNavigate();
  const {isDarkTheme} = useAppContext()
  console.log("DarkTheme",isDarkTheme)

  const handleButtonClick = () => {
    // Redirect to /chat
    navigate("/chat");
  };
  return (
    <div className={styles?.container}>
      <CContainer data-testid="notLogged">
        <CForm>
          <CRow>
            <CCol xs={12}>
              <h1 className={styles?.home_title} style={{ color: isDarkTheme ? "#FFFFFF" : "#000000" }}>
                Site Tech Ops AI
              </h1>
            </CCol>
          </CRow>
          <CRow>
            <CCol xs={12}>
              <div style={{ display: "inline-block" }}>
                <h1 className={styles?.home_subtitle_left}>AI Powered</h1>
              </div>
              <div
                style={{
                  display: "inline-block",
                  marginLeft: "10px",
                }}
              >
                <h1 className={`${styles?.home_subtitle_right} home_subtitle_right`}>Knowledge Base</h1>
              </div> 
            </CCol>
          </CRow>
          <p className={styles?.home_description}>
            Revolutionize the way your team collaborates and accesses critical
            knowledge, <br></br>fostering a dynamic and informed workplaces.
          </p>
          <CRow>
            <CCol xs={12}>
              <CButton
                className={`${styles?.home_getstarted_btn} home_getstarted_btn`}
                onClick={handleButtonClick}
              >
                Get Started Now
              </CButton>
            </CCol>
          </CRow>
        </CForm>
        <span
          className={`${styles?.genAIChatIcon} genAIChatIcon`}
          onClick={handleButtonClick}
        ></span>
      </CContainer>
    </div>
  );
};
export default Store;
