import React, { useEffect, useState } from "react";
import {
  CNavItem,
  CNavLink,
  CContainer,
  CHeader,
  CHeaderNav,
  CAvatar,
  CPopover,
  CRow,
  CCol,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CFormCheck,
  CFormSelect,
  CInputGroup,
  CFormRange,
  CInputGroupText,
  CButton,
  CModal,
  CModalHeader,
  CCardTitle,
  CModalBody,
  CCard,
  CCardBody,
  CCardText,
  CModalFooter,
  CTooltip,
} from "@coreui/react";
import {
  cilAppsSettings,
  cilChevronBottom,
  cilChevronCircleDownAlt,
  cilInfo,
  cilSettings,
} from "@coreui/icons";
// import AppBreadcrumb from "./AppBreadcrumb";
import appLogo from "../assets/brand/hm-logo.png";

import { useMsal } from "@azure/msal-react";
import "../assets/scss/_headertitle.scss";
// import { getAppInsights } from "../services/TelemetryService";
import { getInitials } from "./CommonUtils/CommonUtils";
import {
  environmentData,
  EnvironmentData,
  EnvironmentConfig,
} from "../environments/env";
import { useAppContext } from "./ContextAndProvider/Context";
import CIcon from "@coreui/icons-react";
import Switch from 'react-switch'
import dark from '../assets/images/dark.svg'
import light from '../assets/images/light.svg'
interface AppHeaderProps {
  userDetails: any[];
  toggleClick: (isDarkTheme:boolean) => void;
  showBamHeader: boolean;
}
const AppHeader: React.FC<AppHeaderProps> = ({ userDetails, toggleClick, showBamHeader }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const toggleModal = (e?: React.MouseEvent | React.KeyboardEvent) => {
    if (e) {
      e.stopPropagation(); // Prevent event bubbling
    }
    setModalVisible((prevVisible) => !prevVisible);
  };

  const { instance } = useMsal();
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;

  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;
  // let appInsights

  // useEffect(() => {
  //   appInsights = getAppInsights()
  // }, [])

  const handleLogout = (logoutType: string) => {
    if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
      sessionStorage.clear();
      localStorage.clear();
      instance.logoutRedirect();
    }
  };
  const {
    modelValue,
    setModelValue,
    maxResponse,
    setMaxResponse,
    temperature,
    setTemperature,
    topP,
    setTopP,
    pastMessage,
    setPastMessage,
    isDarkTheme,
    setIsDarkTheme
  } = useAppContext(); // Default selected option
  const toggleTheme = () => {
    let temp = !isDarkTheme;
    setIsDarkTheme(temp)
    toggleClick(temp)
  }
  const handleOptionChange = (option: any) => {
    setModelValue(option);
  };
  const handleModelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setModelValue(e.target.value);
  };
  const handleHideOnClickOutside = (event: any) => {
    console.log(event.target);
    if (!event.target.closest(".info-icon")) {
      // Check if clicked outside the modal
      console.log("Close");
    } else {
      console.log("Open");
    }
  };

  return (
    <CHeader position="sticky">
      <CContainer fluid className="px-0">
        <CRow className="w-100 mx-0">
          <CCol md={2} className="d-flex align-items-center">
            <CHeaderNav className="d-none d-md-flex">
              <CNavItem className="py-1 me-4">
                <img src={appLogo} alt="Logo" className="sidebar-icon" />
                {/* <h1 style={{ color: "white" }}>TCS</h1> */}
              </CNavItem>
            </CHeaderNav>
          </CCol>
          <CCol className="d-flex justify-content-center align-items-center">
            <CHeaderNav className="d-none d-md-flex mx-auto">
              <CNavItem>
                {/* <div className="headertitle">AI Companion</div> */}
                <CButton
                  color="primary"
                  onClick={toggleModal}
                  className="ChatDropDownToggle"
                >
                  Site Tech Ops AI <CIcon icon={cilChevronBottom} size="sm" />
                </CButton>

                <CModal
                  visible={modalVisible}
                  onClose={() => {
                    setModalVisible(false);
                  }}
                  backdrop={true}
                  className="custom-modal"
                  onClick={handleHideOnClickOutside}
                >
                  <CModalHeader closeButton>
                    <CCardTitle>Site Tech Ops AI Settings</CCardTitle>
                  </CModalHeader>
                  <CModalBody className="custom-modal-body">
                    <CCard>
                      <CCardBody>
                        <CCardText>
                          <div className="row">
                            <div className="col-md-4">
                              <label htmlFor="gpt-model">GPT Model</label>
                            </div>
                            <div className="col-md-8">
                              <CFormSelect
                                id="gpt-model"
                                name="GPT Model"
                                value={modelValue?.toString()}
                                onChange={handleModelChange}
                              >
                                <option value={environmentConfig.gpt3key}>
                                  GPT-3.5
                                </option>
                                <option value={environmentConfig.gpt4key}>
                                  GPT-4
                                </option>
                              </CFormSelect>
                            </div>
                          </div>
                        </CCardText>
                        <CCardText>
                          <div className="row align-items-center">
                            <div className="col-md-4">
                              <label htmlFor="max-response">
                                Max Response
                                <CPopover
                                  content="Set a limit on the number of tokens per model response. The API supports a maximum of MaxTokensPlaceholderDoNotTranslate tokens shared between the prompt (including system message, examples, message history, and user query) and the model's response. One token is roughly 4 characters for typical English text. 
                                Recommended: 1200"
                                  placement="right"
                                  trigger={["hover", "focus"]}
                                  // trigger="focus"
                                >
                                  <CIcon
                                    icon={cilInfo}
                                    style={{
                                      marginLeft: "5px",
                                      cursor: "pointer",
                                    }}
                                    className="info-icon"
                                  />
                                </CPopover>
                              </label>
                            </div>
                            <div className="col-md-8">
                              <CInputGroup className="custom-input-group">
                                <div className="col-md-9">
                                  <CFormRange
                                    id="max-response"
                                    name="Max Response"
                                    min={0}
                                    max={3000}
                                    value={maxResponse?.toString()}
                                    onChange={(e) =>
                                      setMaxResponse(Number(e.target.value))
                                    }
                                  />
                                </div>
                                <div
                                  className="col-md-3"
                                  style={{ paddingLeft: "10px" }}
                                >
                                  <CInputGroupText className="custom-input-group-text">
                                    {maxResponse}
                                  </CInputGroupText>
                                </div>
                              </CInputGroup>
                            </div>
                          </div>
                        </CCardText>
                        <CCardText>
                          <div className="row align-items-center">
                            <div className="col-md-4">
                              <label htmlFor="temperature">
                                Temperature
                                <CPopover
                                  content="Controls randomness. Lowering the temperature means that the model will produce more repetitive and deterministic responses. Increasing the temperature will result in more unexpected or creative responses. Try adjusting temperature or Top P but not both. 
                                Recommended: 0"
                                  placement="right"
                                  trigger={["hover", "focus"]}
                                >
                                  <CIcon
                                    icon={cilInfo}
                                    style={{
                                      marginLeft: "5px",
                                      cursor: "pointer",
                                    }}
                                    className="info-icon"
                                  />
                                </CPopover>
                              </label>
                            </div>
                            <div className="col-md-8">
                              <CInputGroup>
                                <div className="col-md-9">
                                  <CFormRange
                                    id="temperature"
                                    name="Temperature"
                                    min={0}
                                    max={1}
                                    step={0.01}
                                    value={temperature?.toString()}
                                    onChange={(e) =>
                                      setTemperature(Number(e.target.value))
                                    }
                                  />
                                </div>
                                <div
                                  className="col-md-3"
                                  style={{ paddingLeft: "10px" }}
                                >
                                  <CInputGroupText>
                                    {temperature}
                                  </CInputGroupText>
                                </div>
                              </CInputGroup>
                            </div>
                          </div>
                        </CCardText>
                        <CCardText>
                          <div className="row">
                            <div className="col-md-4">
                              <label htmlFor="top-p">
                                Top P
                                <CPopover
                                  content="Similar to temperature, this controls randomness but uses a different method. Lowering Top P will narrow the model’s token selection to likelier tokens. Increasing Top P will let the model choose from tokens with both high and low likelihood. Try adjusting temperature or Top P but not both. 
                                Recommended: 1"
                                  placement="right"
                                  trigger={["hover", "focus"]}
                                >
                                  <CIcon
                                    icon={cilInfo}
                                    style={{
                                      marginLeft: "5px",
                                      cursor: "pointer",
                                    }}
                                    className="info-icon"
                                  />
                                </CPopover>
                              </label>
                            </div>
                            <div className="col-md-8">
                              <CInputGroup>
                                <div className="col-md-9">
                                  <CFormRange
                                    id="top-p"
                                    name="Top P"
                                    min={0}
                                    max={1}
                                    step={0.01}
                                    value={topP?.toString()}
                                    onChange={(e) =>
                                      setTopP(Number(e.target.value))
                                    }
                                  />
                                </div>
                                <div
                                  className="col-md-3"
                                  style={{ paddingLeft: "10px" }}
                                >
                                  <CInputGroupText>{topP}</CInputGroupText>
                                </div>
                              </CInputGroup>
                            </div>
                          </div>
                        </CCardText>
                        <CCardText>
                          <div className="row">
                            <div className="col-md-4">
                              <label htmlFor="top-p">
                                Past Messages Included
                                <CPopover
                                  content="Select the number of past messages to include in each new API request. This helps give the model context for new user queries. Setting this number to 10 will include 5 user queries and 5 system responses. 
                                Recommended: 3"
                                  placement="right"
                                  trigger={["hover", "focus"]}
                                >
                                  <CIcon
                                    icon={cilInfo}
                                    style={{
                                      marginLeft: "5px",
                                      cursor: "pointer",
                                    }}
                                    className="info-icon"
                                  />
                                </CPopover>
                              </label>
                            </div>
                            <div className="col-md-8">
                              <CInputGroup>
                                <div className="col-md-9">
                                  <CFormRange
                                    id="pastmessageincluded"
                                    name="Past Messages Included"
                                    min={0}
                                    max={10}
                                    step={1}
                                    value={pastMessage?.toString()}
                                    onChange={(e) =>
                                      setPastMessage(Number(e.target.value))
                                    }
                                  />
                                </div>
                                <div
                                  className="col-md-3"
                                  style={{ paddingLeft: "10px" }}
                                >
                                  <CInputGroupText>
                                    {pastMessage}
                                  </CInputGroupText>
                                </div>
                              </CInputGroup>
                            </div>
                          </div>
                        </CCardText>
                      </CCardBody>
                    </CCard>
                  </CModalBody>
                </CModal>
              </CNavItem>
            </CHeaderNav>
          </CCol>
          <CCol
            md={2}
            className="d-flex justify-content-end align-items-center"
          >
            <CHeaderNav className="profilelogo">
            {!showBamHeader ? (
              <CNavItem className="d-flex align-items-center pe-2">
                <Switch
                  onChange={toggleTheme}
                  offColor="#808080"
                  onColor="#FFFFFF"
                  offHandleColor="#FFFFFF"
                  onHandleColor="#808080"
                  checked={isDarkTheme}
                  uncheckedIcon={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',

                        paddingRight: 2,
                      }}
                    >
                      <img src={dark} alt="dark" />
                    </div>
                  }
                  checkedIcon={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',

                        paddingRight: 2,
                      }}
                    >
                      <img src={light} alt="light" />
                    </div>
                  }
                  uncheckedHandleIcon={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',

                        paddingRight: 2,
                      }}
                    >
                      <img src={light} alt="light" />
                    </div>
                  }
                  checkedHandleIcon={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <img src={dark} alt="light" />
                    </div>
                  }
                  className="react-switch"
                />
              </CNavItem>
            ) : (
              ''
            )}
              <CNavItem className="d-flex align-items-center">
                <CAvatar
                  className="profileAvatar"
                  data-coreui-toggle="tooltip"
                  data-coreui-placement="bottom"
                  title={userDetails?.[0]?.userName}
                  textColor="white"
                  data-testid="userName"
                >
                  {userDetails.length > 0 &&
                    getInitials(userDetails?.[0]?.userName)}
                </CAvatar>
              </CNavItem>
              <CNavItem>
                <CNavLink
                  onClick={() => handleLogout("redirect")}
                  data-testid="logoutIcon"
                >
                  <CAvatar className="circleIcon">
                    <span className="logoutSpriteIcon"></span>
                  </CAvatar>
                </CNavLink>
              </CNavItem>
            </CHeaderNav>
          </CCol>
        </CRow>
      </CContainer>
    </CHeader>
  );
};

export default AppHeader;
