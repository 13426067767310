import React, { useEffect, useMemo, useState } from "react";
import { useBoolean } from "@fluentui/react-hooks";
import {
  FontIcon,
  Stack,
  Text,
  PrimaryButton,
  DefaultButton,
  DefaultEffects
} from "@fluentui/react";
import { invokeApi } from "../../api";

import styles from "./Answer.module.css";

import { AskResponse, Citation } from "../../api";
import { parseAnswer } from "./AnswerParser";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import supersub from "remark-supersub";
import { useAppContext } from "../ContextAndProvider/Context";
import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane
} from "@coreui/react";
import Recommendation from "../Recommendation/Recommendation";
import vertex_loader from "../../assets/images/gifs/vertex_loader.gif";
import hai_loader from "../../assets/images/gifs/hai_loader.gif";
import servicenow_loader from "../../assets/images/gifs/servicenow_loader.gif";
import store_loader from "../../assets/images/gifs/store_loader.gif";
import salesintelligent_loader from "../../assets/images/gifs/intelligence.gif";
import storenetwork_loader from "../../assets/images/gifs/network.gif";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  PieChart,
  Pie
} from "recharts";
interface FormattedData {
  [key: string]: string | number;
}
interface Props {
  answer: AskResponse;
  onCitationClicked: (citedDocument: Citation) => void;
  currentquestion: string;
  selectedindex: string;
  answerindex?: number;
}

export const Answer = ({
  answer,
  currentquestion,
  onCitationClicked,
  selectedindex,
  answerindex
}: Props) => {
  const [data, setData] = useState([]);
  const [xaxis, setXaxis] = useState([]);
  const [dataKey, setDataKey] = useState([]);
  const [prevAnswer, setPreviousAnswer] = useState<AskResponse | undefined>(
    undefined
  );
  const [prevAnswerTimeStamp, setPreviousAnswerTimeStamp] = useState<number>();
  const [activeItemKey, setActiveItemKey] = useState<number | undefined>(
    undefined
  );
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (tabIndex: any) => {
    setActiveTab(tabIndex);
  };
  const facts: string[] = [
    "The term 'Artificial Intelligence' was coined by computer scientist John McCarthy in 1956 during the Dartmouth Conference.",
    "AI is used in various everyday applications, such as virtual assistants like Siri and Alexa, recommendation systems on streaming platforms, and spam filters in email.",
    "Machine Learning, a subset of AI, enables computers to learn from data and improve over time without being explicitly programmed.",
    "Deep Learning, a further subset of Machine Learning, mimics the human brain's neural networks and has led to breakthroughs in areas like computer vision and natural language processing.",
    "AI ethics is a growing concern, with issues such as bias in algorithms, privacy concerns, and the potential for job displacement due to automation.",
    "AI is being increasingly adopted across industries, including healthcare (diagnosis and treatment), finance (fraud detection and trading algorithms), and transportation (autonomous vehicles).",
    "The development of AI is driven by a combination of academic research, industry investment, and government funding in countries around the world.",
    "The field of AI continues to evolve rapidly, with new breakthroughs and applications emerging regularly, shaping the way we live and work.",
    "As AI becomes more integrated into society, understanding its capabilities, limitations, and ethical implications is crucial for responsible deployment and use.",
    "AI has been around since the mid-20th century but has recently gained significant traction due to advancements in computing power and data availability."
  ];
  const [currentFactIndex, setCurrentFactIndex] = useState(0);
  let selectedImage: string;
  switch (selectedindex) {
    case "Vertex":
      selectedImage = vertex_loader;
      break;
    case "HAI Buddy":
      selectedImage = hai_loader;
      break;
    case "Business Impact":
      selectedImage = servicenow_loader;
      break;
    case "Store Manager":
      selectedImage = store_loader;
      break;
    case "Sales Intelligent":
      selectedImage = salesintelligent_loader;
      break;
    case "Store Network":
      selectedImage = storenetwork_loader;
      break;
    default:
      selectedImage = servicenow_loader; // Default to image1 or provide a fallback
  }
  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     // Move to the next fact
  //     setCurrentFactIndex((prevIndex) =>
  //       prevIndex < facts.length - 1 ? prevIndex + 1 : 0
  //     );
  //   }, 5000);

  //   // Clean up the timer on component unmount
  //   return () => clearInterval(timer);
  // }, [facts.length]);
  const bottomOfPanel = React.useRef<HTMLDivElement>(null);

  const {
    answerStatus,
    setAnswerStatus,
    generatedAns,
    setGeneratedAns,

    isSidebarOpen,
    setSidebarOpen
  } = useAppContext();
  const [isRefAccordionOpen, { toggle: toggleIsRefAccordionOpen }] =
    useBoolean(false);
  const filePathTruncationLimit = 50;

  const parsedAnswer = useMemo(() => parseAnswer(answer), [answer]);
  const [chevronIsExpanded, setChevronIsExpanded] =
    useState(isRefAccordionOpen);
  useEffect(() => {
    if (!prevAnswer || !deepEqual(answer, prevAnswer)) {
      setPreviousAnswer(answer);
      const currentTimestamp = Date.now();
      setPreviousAnswerTimeStamp(currentTimestamp);

      bottomOfPanel.current?.scrollIntoView({ behavior: "smooth" });
    }
    if (prevAnswerTimeStamp) {
      let savedTimeDiff = calculateTimestampDifference(prevAnswerTimeStamp);

      if (savedTimeDiff < 3) {
        bottomOfPanel.current?.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (answer.answer == "Generating answer...") {
      setTimeout(() => {
        if (bottomOfPanel.current) {
          bottomOfPanel.current.scrollIntoView({ behavior: "smooth" });
        } else {
        }
      }, 300);
    }
  }, [answer, isSidebarOpen]);
  const calculateTimestampDifference = (previousTimestamp: any) => {
    const currentTimestamp = Date.now();
    const difference = currentTimestamp - previousTimestamp;
    return difference / 1000; // Convert to seconds (optional)
  };
  const handleChevronClick = () => {
    setChevronIsExpanded(!chevronIsExpanded);
    toggleIsRefAccordionOpen();
  };

  useEffect(() => {
    setChevronIsExpanded(isRefAccordionOpen);
  }, [isRefAccordionOpen]);
  function deepEqual(objA: any, objB: any): boolean {
    if (objA === objB) {
      return true;
    }

    if (
      typeof objA !== "object" ||
      objA === null ||
      typeof objB !== "object" ||
      objB === null
    ) {
      return false;
    }

    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);

    if (keysA.length !== keysB.length) {
      return false;
    }

    for (let key of keysA) {
      if (!keysB.includes(key) || !deepEqual(objA[key], objB[key])) {
        return false;
      }
    }

    return true;
  }
  const createCitationFilepath = (
    citation: Citation,
    index: number,
    truncate: boolean = false
  ) => {
    let citationFilename = "";

    if (citation.filepath && citation.chunk_id) {
      if (truncate && citation?.filepath?.length > filePathTruncationLimit) {
        const citationLength = citation?.filepath?.length;
        citationFilename = `${citation.filepath.substring(
          0,
          20
        )}...${citation?.filepath?.substring(citationLength - 20)} - Part ${
          parseInt(citation.chunk_id) + 1
        }`;
      } else {
        citationFilename = `${citation?.filepath} - Part ${
          parseInt(citation?.chunk_id) + 1
        }`;
      }
    } else {
      citationFilename = `Citation ${index}`;
    }
    return citationFilename;
  };
  if (
    parsedAnswer.markdownFormatText === "" ||
    parsedAnswer.markdownFormatText === "Generating answer..."
  ) {
    setAnswerStatus(false);
  } else {
    setAnswerStatus(true);
    setGeneratedAns(parsedAnswer.markdownFormatText);
  }
  const handleAccordionClick = (itemKey: number, text: string) => {
    let dt = getChartData(text);
    if (dt?.length > 0) {
      setData(dt);
    }
    setActiveItemKey((prevKey) => (prevKey === itemKey ? undefined : itemKey));
  };
  const getChartData = (text: string): any => {
    const replacedText = text.replace(
      /\[INC\d+\]\(.*?number=(INC\d+)\)/g,
      "$1"
    );
    const tableRows = replacedText
      .split("\n")
      .map((row) => row.trim())
      .filter((row) => !row.startsWith("|----")) // Ignore lines with horizontal lines
      .map((row) => row.split("|").filter((cell) => cell.trim() !== ""))
      .filter((row) => row.length > 1);
    if (tableRows.length > 0) {
      // Make the first row bold
      const boldTableRows = [
        tableRows[0].map((cell) => cell.replace(/^\s*|\s*$/g, ""))
      ];

      for (let i = 1; i < tableRows.length; i++) {
        boldTableRows.push(tableRows[i].map((cell) => cell.trim()));
      }

      let finalTableRows = boldTableRows;

      if (boldTableRows[0].length === 2) {
        // If there are exactly 2 columns, pivot the table
        const headers = [boldTableRows[0][0]]; // The first column becomes headers
        const firstColVal = [boldTableRows[0][1]];
        const dataRows = boldTableRows.slice(1);

        // Type annotation for pivotedData
        const pivotedData: { [key: string]: string[] } = {};

        dataRows.forEach((row) => {
          const [header, value] = row;
          if (!pivotedData[header]) {
            pivotedData[header] = [];
          }
          pivotedData[header].push(value);
        });

        finalTableRows = [headers.concat(Object.keys(pivotedData))];
        const maxRowLength = Math.max(
          ...Object.values(pivotedData).map((values) => values.length)
        );

        for (let i = 0; i < maxRowLength; i++) {
          const newRow = [firstColVal[0]];
          Object.values(pivotedData).forEach((values) => {
            newRow.push(values[i] || "");
          });
          finalTableRows.push(newRow);
        }
        const formattedData = transformData(finalTableRows);
        return formattedData;
      } else {
        const filteredData = boldTableRows.map((row, index) => {
          // Assuming the structure is consistent and "Total Sales" is the last column
          return [row[0], row[2]]; // Adjust index if needed, e.g., row[2] for "Total Sales"
        });

        return filteredData;
      }
    }
  };
  const transformData = (data: string[][]): FormattedData[] => {
    const [headers, ...rows] = data;
    const formattedData: FormattedData[] = [];

    // Iterate through rows and create objects
    for (let i = 1; i < headers.length; i++) {
      const obj: FormattedData = {};
      obj[headers[0]] = headers[i];
      obj[rows[0][0]] = parseFloat(rows[0][i].replace(/[^0-9.]/g, ""));
      formattedData.push(obj);
    }

    return formattedData;
  };
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      let elementsToRemove: string[] = [
        "payload",
        "cx",
        "cy",
        "fill",
        "stroke"
      ];

      // Create a new list excluding the elements to remove
      let filteredList: string[] = Object.keys(data)?.filter(
        (item) => !elementsToRemove.includes(item)
      );

      const keys = filteredList;

      // Assuming that the first key is 'Product Type' and the second key is 'Sales Volume (USD)'
      const productTypeKey = keys[0];
      const salesVolumeKey = keys[1];

      const productType = data[productTypeKey];
      const salesVolume = data[salesVolumeKey];

      return (
        <div className="custom-tooltip">
          <p className="label">{`${productType}: ${salesVolume}`}</p>
        </div>
      );
    }
    return null;
  };
  return (
    <CRow className="mx-0 mb-3 fullAnsCont">
      <CCol className={`px-0 ${styles?.answerIcon}`}>
        <span className={`${styles?.titleIcon} titleIcon`}></span>
      </CCol>
      <CCol className={`px-0 ${styles?.answerContainerCol}`} md={11}>
        {parsedAnswer?.markdownFormatText ===
        "Sorry, unable to generate response at the moment. Please try changing the GPT model or try again later." ? (
          <>
          <div className="sorryDiv">
            <Stack className={styles?.answerContainer}>
              <Stack.Item grow className={styles?.ansInnerCnt}>
                <ReactMarkdown
                  linkTarget="_blank"
                  remarkPlugins={[remarkGfm, supersub]}
                  children={parsedAnswer?.markdownFormatText}
                  className={`${styles?.answerText} ${"answerText"}`}
                />
              </Stack.Item>
              <Stack horizontal className={styles?.answerFooter}>
                {!!parsedAnswer?.citations?.length && (
                  <Stack.Item aria-label="References">
                    <Stack style={{ width: "100%" }}>
                      <Stack
                        horizontal
                        horizontalAlign="start"
                        verticalAlign="center"
                      >
                        <Text
                          className={styles?.accordionTitle}
                          onClick={toggleIsRefAccordionOpen}
                        >
                          <span>
                            {parsedAnswer?.citations?.length > 1
                              ? parsedAnswer?.citations?.length + " references"
                              : "1 reference"}
                          </span>
                        </Text>
                        <FontIcon
                          className={styles?.accordionIcon}
                          onClick={handleChevronClick}
                          iconName={
                            chevronIsExpanded ? "ChevronDown" : "ChevronRight"
                          }
                        />
                      </Stack>
                    </Stack>
                  </Stack.Item>
                )}
              </Stack>
              {chevronIsExpanded && (
                <div
                  style={{
                    marginTop: 8,
                    display: "flex",
                    flexFlow: "wrap column",
                    maxHeight: "150px",
                    gap: "4px"
                  }}
                >
                  {parsedAnswer?.citations?.map((citation, idx) => (
                    <span
                      title={createCitationFilepath(citation, ++idx)}
                      key={idx}
                      onClick={() => onCitationClicked(citation)}
                      className={styles?.citationContainer}
                    >
                      <div className={styles?.citation}>{idx}</div>
                      {createCitationFilepath(citation, idx, true)}
                    </span>
                  ))}
                </div>
              )}
            </Stack>
            </div>
          </>
        ) : (
                <Stack className={styles?.answerContainer}>
                  <Stack.Item grow className={styles?.ansInnerCnt}>
                    {parsedAnswer?.markdownFormatText === "" ||
                    parsedAnswer?.markdownFormatText ===
                      "Generating answer..." ? (
                      <div className={styles?.loadingimgdiv}>
                        <img
                          className={styles.loadingimg}
                          src={selectedImage}
                          alt="loading"
                        />
                      </div>
                    ) : (
                      <ReactMarkdown
                        linkTarget="_blank"
                        remarkPlugins={[remarkGfm, supersub]}
                        children={parsedAnswer?.markdownFormatText}
                        className={`${styles?.answerText} ${"answerText"}`}
                      />
                    )}
                  </Stack.Item>
                  <Stack horizontal className={styles?.answerFooter}>
                    {!!parsedAnswer?.citations?.length && (
                      <Stack.Item aria-label="References">
                        <Stack style={{ width: "100%" }}>
                          <Stack
                            horizontal
                            horizontalAlign="start"
                            verticalAlign="center"
                          >
                            <Text
                              className={styles?.accordionTitle}
                              onClick={toggleIsRefAccordionOpen}
                            >
                              <span>
                                {parsedAnswer?.citations?.length > 1
                                  ? parsedAnswer?.citations?.length +
                                    " references"
                                  : "1 reference"}
                              </span>
                            </Text>
                            <FontIcon
                              className={styles?.accordionIcon}
                              onClick={handleChevronClick}
                              iconName={
                                chevronIsExpanded
                                  ? "ChevronDown"
                                  : "ChevronRight"
                              }
                            />
                          </Stack>
                        </Stack>
                      </Stack.Item>
                    )}
                  </Stack>
                  {chevronIsExpanded && (
                    <div
                      style={{
                        marginTop: 8,
                        display: "flex",
                        flexFlow: "wrap column",
                        maxHeight: "150px",
                        gap: "4px"
                      }}
                    >
                      {parsedAnswer?.citations?.map((citation, idx) => (
                        <span
                          title={createCitationFilepath(citation, ++idx)}
                          key={idx}
                          onClick={() => onCitationClicked(citation)}
                          className={styles?.citationContainer}
                        >
                          <div className={styles?.citation}>{idx}</div>
                          {createCitationFilepath(citation, idx, true)}
                        </span>
                      ))}
                    </div>
                  )}
                </Stack>
        )}
        {/* {parsedAnswer.markdownFormatText === "" ||
          (parsedAnswer.markdownFormatText === "Generating answer..." && (
            <span className={styles.quickFacts}>
              <b>Quick facts :</b>
              {facts[currentFactIndex]}
            </span>
          ))} */}
        {answerStatus && <Recommendation answerindex={answerindex} />}
      </CCol>
      <CCol></CCol>
      <div ref={bottomOfPanel}></div>
    </CRow>
  );
};
