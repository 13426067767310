import { ReactNode, useState } from "react";
import AppContext from "./Context";
import {
  environmentData,
  EnvironmentData,
  EnvironmentConfig
} from "../../environments/env";

interface AppProviderProps {
  children: ReactNode;
}
interface Item {
  label: string;
  value: boolean;
}

interface UserConfig {
  Rephrase: Item[];
  AdjustTone: Item[];
}
export type ChatMessage = {
  role: string;
  content: string;
  end_turn?: boolean;
  callback?: Function;
  noAnsCallback?: Function;
  email?: string;
  reward?: number;
  penalty?: number;
};
export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;

  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [likeStatus, setLikeStatus] = useState<boolean>(false);
  const [dislikeStatus, setDislikeStatus] = useState<boolean>(false);
  const [videoModal, setVideoModal] = useState<boolean>(false);
  const [activeRecommendation, setActiveRecommendation] = useState<string>("");
  const [generatedAns, setGeneratedAns] = useState<string>("");
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [answerStatus, setAnswerStatus] = useState<boolean>(false);
  const [isFocusTextField, setIsFocusTextField] = useState<boolean>(false);
  const [selectedSystem, setSelectedSystem] = useState<string>("");
  const [answers, setAnswers] = useState<ChatMessage[]>([]);
  const [grammarPopVisible, setGrammarlyPopVisible] = useState<boolean>(false);
  const [sampleQnPopVisible, setSampleQnPopVisible] = useState<boolean>(false);
  const [activeGrammar, setActiveGrammar] = useState<String | null>("");
  const [modelValue, setModelValue] = useState<String | null>(
    environmentConfig.gpt3key
  );
  const [maxResponse, setMaxResponse] = useState<number | null | undefined>(
    800
  );
  const [temperature, setTemperature] = useState<number | null | undefined>(0);
  const [topP, setTopP] = useState<number | null | undefined>(1);
  const [pastMessage, setPastMessage] = useState<number | null | undefined>(0);
  const [isDarkTheme, setIsDarkTheme] = useState<boolean>(true);
  const [gramerlyContent, setGramerlyContent] = useState<UserConfig>({
    Rephrase: [
      {
        label: "Shorten it",
        value: false
      },
      {
        label: "Make it more descriptive",
        value: false
      },
      {
        label: "Make it more detailed",
        value: false
      },
      {
        label: "Simplify it",
        value: false
      },
      {
        label: "Sound professional",
        value: false
      }
    ],
    AdjustTone: [
      {
        label: "Make it assertive",
        value: false
      },
      {
        label: "Sound confident",
        value: false
      },
      {
        label: "Make it constructive",
        value: false
      },
      {
        label: "Make it diplomatic",
        value: false
      },
      {
        label: "Make it empathetic",
        value: false
      }
    ]
  });

  const mockFileData: Array<string> = [
    "WordFileTitle",
    "ExcelFileTitle",
    "PPTFileTitle",
    "PDFFileTitle"
  ];
  const mockLinkData: Array<string> = [
    "https://brave-bay-06e517203.4.azurestaticapps.net",
    "https://brave-bay-06e517203.4.azurestaticapps.net",
    "https://brave-bay-06e517203.4.azurestaticapps.net",
    "https://brave-bay-06e517203.4.azurestaticapps.net"
  ];
  const mockHistoryData = {
    Yesterday: ["History1", "History2", "History3"],
    "29/01/2024": ["History1"],
    "24/01/2024": ["History1", "History2"]
  };
  const contextValue = {
    isSidebarOpen,
    setSidebarOpen,
    activeRecommendation,
    setActiveRecommendation,
    mockFileData,
    mockLinkData,
    mockHistoryData,
    visibleModal,
    setVisibleModal,
    answerStatus,
    setAnswerStatus,
    generatedAns,
    setGeneratedAns,
    isMobile,
    setIsMobile,
    videoModal,
    setVideoModal,
    popoverVisible,
    setPopoverVisible,
    likeStatus,
    setLikeStatus,
    dislikeStatus,
    setDislikeStatus,
    isFocusTextField,
    setIsFocusTextField,
    selectedSystem,
    setSelectedSystem,
    gramerlyContent,
    setGramerlyContent,
    answers,
    setAnswers,
    grammarPopVisible,
    setGrammarlyPopVisible,
    sampleQnPopVisible,
    setSampleQnPopVisible,
    activeGrammar,
    setActiveGrammar,
    modelValue,
    setModelValue,
    temperature,
    setTemperature,
    maxResponse,
    setMaxResponse,
    topP,
    setTopP,
    pastMessage,
    setPastMessage,
    isDarkTheme,
    setIsDarkTheme
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
