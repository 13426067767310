import {
  CButton,
  CCol,
  CModal,
  CRow,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle
} from "@coreui/react";
import React, { useState } from "react";
import { useAppContext } from "../ContextAndProvider/Context";
import styles from "./Recommendation.module.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import RecommendationTab from "./RecommendationTab";
import * as XLSX from "xlsx";
import { first } from "lodash";
interface Props {
  answerindex?: number;
}
const Recommendation = ({ answerindex }: Props) => {
  const {
    isSidebarOpen,
    setSidebarOpen,
    activeRecommendation,
    setActiveRecommendation,
    generatedAns,
    isMobile,
    visibleModal,
    setVisibleModal,
    popoverVisible,
    setPopoverVisible,
    likeStatus,
    setLikeStatus,
    dislikeStatus,
    setDislikeStatus,
    answers,
    setAnswers
  } = useAppContext();

  // let recommendationButtons = ["Watch Videos", "Files", "Links"];
  let recommendationButtons: any = [];
  const [copyBox, setCopyBox] = useState<boolean>(false);
  const handleRecommendationClick = (buttonName: string) => {
    setActiveRecommendation(buttonName);
    !isMobile && setSidebarOpen(true);
    isMobile && setVisibleModal(true);
  };
  const copyText = () => {
    setCopyBox(true);
    setTimeout(() => {
      setCopyBox(false);
    }, 2000);
  };
  const downloadExcel = (filename: string, text: string) => {
    const replacedText = text.replace(
      /\[INC\d+\]\(.*?number=(INC\d+)\)/g,
      "$1"
    );
    console.log("text", replacedText, "filename", filename);
    const tableRows = replacedText
      .split("\n")
      .map((row) => row.trim())
      .filter((row) => !row.startsWith("|----")) // Ignore lines with horizontal lines
      .map((row) => row.split("|").filter((cell) => cell.trim() !== ""))
      .filter((row) => row.length > 1);

    // Make the first row bold
    const boldTableRows = [
      tableRows[0].map((cell) => cell.replace(/^\s*|\s*$/g, ""))
    ];

    for (let i = 1; i < tableRows.length; i++) {
      boldTableRows.push(tableRows[i].map((cell) => cell.trim()));
    }

    let finalTableRows = boldTableRows;

    if (boldTableRows[0].length === 2) {
      // If there are exactly 2 columns, pivot the table
      const headers = [boldTableRows[0][0]]; // The first column becomes headers
      const firstColVal = [boldTableRows[0][1]];
      const dataRows = boldTableRows.slice(1);

      // Type annotation for pivotedData
      const pivotedData: { [key: string]: string[] } = {};

      dataRows.forEach((row) => {
        const [header, value] = row;
        if (!pivotedData[header]) {
          pivotedData[header] = [];
        }
        pivotedData[header].push(value);
      });

      finalTableRows = [headers.concat(Object.keys(pivotedData))];
      const maxRowLength = Math.max(
        ...Object.values(pivotedData).map((values) => values.length)
      );

      for (let i = 0; i < maxRowLength; i++) {
        const newRow = [firstColVal[0]];
        Object.values(pivotedData).forEach((values) => {
          newRow.push(values[i] || "");
        });
        finalTableRows.push(newRow);
      }
      if (firstColVal[0].startsWith("INC")) {
        filename = `${firstColVal[0]}.xlsx`;
      }
    }

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(finalTableRows);
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
    XLSX.writeFile(workbook, filename);
  };

  const handleDownloadClick = () => {
    const text = generatedAns;
    if (text.includes("|")) {
      downloadExcel("download.xlsx", text);
    } else {
      downloadTextAsFile("download.txt", text);
    }
  };
  const likeOnclick = (idx?: number) => {
    if (idx) {
      let newAnswers = [...answers];
      newAnswers[idx].reward = 1;
      newAnswers[idx].penalty = 0;
      if (idx > 0) {
        newAnswers[idx - 1].reward = 1;
        newAnswers[idx - 1].penalty = 0;
      }
      setAnswers(newAnswers);
    }
  };
  const dislikeOnclick = (idx?: number) => {
    if (idx) {
      let newAnswers = [...answers];
      newAnswers[idx].reward = 0;
      newAnswers[idx].penalty = 1;
      if (idx > 0) {
        newAnswers[idx - 1].reward = 0;
        newAnswers[idx - 1].penalty = 1;
      }
      setAnswers(newAnswers);
    }
  };
  const downloadTextAsFile = (fileName: string, text: string) => {
    const element = document.createElement("a");
    const file = new Blob([text], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    element.click();
  };
  const mobileBtnname = ["Download", "Watch Videos", "Files", "Links"];
  const ButtonOpions = () => {
    return (
      <div>
        {mobileBtnname?.map((buttonName, ind) => {
          return (
            <div onClick={() => handleRecommendationClick(buttonName)}>
              <CRow
                className={` mx-0 alignProp  ${
                  ind != mobileBtnname?.length - 1 ? "chatPopoverRow" : ""
                }`}
              >
                <CCol
                  md={1}
                  className={` ${styles?.spriteUrl} ${
                    buttonName + "Icon" + " " + "InlineDisplayProperty" + "px-0"
                  }`}
                ></CCol>
                <CCol className="px-0">{buttonName}</CCol>
              </CRow>
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <div className={styles?.recommendationCnt}>
      {recommendationButtons?.map((buttonName: any) => {
        return (
          <>
            {!isMobile && (
              <CButton
                className={`${
                  activeRecommendation === buttonName && isSidebarOpen
                    ? "activeRecommendBtn"
                    : "recommendBtn"
                } ${"InlineDisplayProperty"}`}
                onClick={() => handleRecommendationClick(buttonName)}
              >
                <span
                  className={` ${styles?.spriteUrl} ${
                    buttonName + "Icon" + " " + "InlineDisplayProperty"
                  }`}
                ></span>
                <span className={styles?.btnName}>{buttonName}</span>
              </CButton>
            )}
          </>
        );
      })}
      <span className={styles?.actionIcons}>
        <span
          onClick={() => {
            likeOnclick(answerindex);
            // setLikeStatus(true);
            // setDislikeStatus(false);
          }}
          className={`${styles?.spriteUrl+ ' spriteUrl'} ${
            answerindex && answers[answerindex]?.reward == 1
              ? styles?.likeFilledIcon + " likeFilledIcon"
              : styles?.chatLikeIcon + " chatLikeIcon"
          }`}
        ></span>
        <span
          onClick={() => {
            dislikeOnclick(answerindex);
          }}
          className={`${styles?.spriteUrl+ ' spriteUrl'} ${
            answerindex && answers[answerindex].penalty == 1
              ? styles?.disLikeFilledIcon + " disLikeFilledIcon"
              : styles?.chatDislikeIcon + " chatDislikeIcon"
          }`}
        ></span>

        <CopyToClipboard
          text={answerindex ? answers[answerindex]?.content : ""}
        >
          <span
            className={`${styles?.spriteUrl+ ' spriteUrl'} ${styles?.chatCopyIcon} chatCopyIcon`}
            onClick={copyText}
          ></span>
        </CopyToClipboard>
        {!isMobile && (
          <span
            className={`${styles?.spriteUrl+ ' spriteUrl'} ${styles?.DownloadIcon} DownloadIcon`}
            onClick={handleDownloadClick}
          ></span>
        )}
        {isMobile && (
          <>
            <CDropdown className="moreInfo-ChatWindow" direction="dropup">
              <CDropdownToggle color="secondary">
                <span
                  className={`${styles?.mobSpriteUrl} ${styles?.chatAllOptIcon}`}
                ></span>
              </CDropdownToggle>
              <CDropdownMenu className="dd-menu">
                {mobileBtnname?.map((buttonName, ind) => (
                  <CDropdownItem
                    key={ind} // Use the 'title' property as the key // Update the function parameters
                  >
                    <div onClick={() => handleRecommendationClick(buttonName)}>
                      <CRow
                        className={` mx-0 alignProp  ${
                          ind != mobileBtnname?.length - 1
                            ? "chatPopoverRow"
                            : ""
                        }`}
                      >
                        <CCol
                          md={1}
                          className={` ${styles?.spriteUrl} ${
                            buttonName +
                            "Icon" +
                            " " +
                            "InlineDisplayProperty" +
                            "px-0"
                          }`}
                        ></CCol>
                        <CCol className="px-0">{buttonName}</CCol>
                      </CRow>
                    </div>
                  </CDropdownItem>
                ))}
              </CDropdownMenu>
            </CDropdown>
            <CModal alignment="center" visible={visibleModal}>
              <RecommendationTab />
            </CModal>
          </>
        )}
      </span>
      {copyBox && <div className={styles?.copyBox}>Copied to Clipboard</div>}
    </div>
  );
};
export default Recommendation;
